$primary-color:primary-color;
$primary-font-color:primary-font-color;
$bg-color:bg-color;
$cs-bubble-color:cs-bubble-color;
$cs-font-color:cs-font-color;
@import "./variable.scss";

body {
    margin: 0;
    padding: 0;
    background-color: $bg-color;
    font-size: 13px;
}

.van-toast {
    width: auto;
    max-width: 80%;
    word-break: break-word;
}

.satisfaction-toast {
    padding: 1.5385rem;
    background-color: #242533;
    line-height: 1.6923rem;
}
