$primary-color:primary-color;
$primary-font-color:primary-font-color;
$bg-color:bg-color;
$cs-bubble-color:cs-bubble-color;
$cs-font-color:cs-font-color;


























































































































































































































































































.progress {
    ::v-deep &.ant-spin-dot {
        margin-left: -60px !important;
    }
}
