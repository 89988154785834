$primary-color:primary-color;
$primary-font-color:primary-font-color;
$bg-color:bg-color;
$cs-bubble-color:cs-bubble-color;
$cs-font-color:cs-font-color;

























































































































































































@import "./yqg-simple-form/component/field-rich/rich.scss";

.def-value {
    margin: 0;

    &-rich ::v-deep {
        line-height: 1.5;

        @include rich;
    }
}
